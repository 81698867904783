exports.components = {
  "component---src-pages-2-heroes-jsx": () => import("./../../../src/pages/2heroes.jsx" /* webpackChunkName: "component---src-pages-2-heroes-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-construction-jsx": () => import("./../../../src/pages/construction.jsx" /* webpackChunkName: "component---src-pages-construction-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-dato-cms-product-fandoms-jsx": () => import("./../../../src/pages/{datoCmsProduct.fandoms}.jsx" /* webpackChunkName: "component---src-pages-dato-cms-product-fandoms-jsx" */),
  "component---src-pages-dato-cms-product-product-type-jsx": () => import("./../../../src/pages/{datoCmsProduct.productType}.jsx" /* webpackChunkName: "component---src-pages-dato-cms-product-product-type-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-products-dato-cms-product-slug-jsx": () => import("./../../../src/pages/products/{datoCmsProduct.slug}.jsx" /* webpackChunkName: "component---src-pages-products-dato-cms-product-slug-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-templates-product-type-filter-jsx": () => import("./../../../src/templates/productTypeFilter.jsx" /* webpackChunkName: "component---src-templates-product-type-filter-jsx" */)
}

